<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editKbBuchung')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createKbBuchung')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.title"
                                        name="title"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        @keyup.enter="onSubmit()"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <zw-select-group v-model="form.tax"
                                         :options="getVats() | optionsVL"
                                         name="tax"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-select-group v-model="form.konto_id"
                                         name="kbkonto_title"
                                         :options="getKbkontos()"
                                         text-field="title"
                                         value-field="id"
                                         validate="required"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Kbbuchung-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
            },
            form: {},
            labelPrefix: 'settings.kbbuchungs.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getVats']),
        ...mapGetters('Kbbuchung', ['getKbbuchung']),
        ...mapGetters('Kbkonto', ['getKbkontos']),
        shown() {
            const kbbuchung = this.$store.dispatch('Kbbuchung/fetchKbbuchung', this.payload.id)
            const kbkontos = this.$store.dispatch('Kbkonto/fetchKbkontos')

            Promise.all([kbbuchung, kbkontos])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getKbbuchung()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('Kbbuchung/saveKbbuchung', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>